<template>
  <v-card>
    <v-card-title>Create Organization</v-card-title>
    <v-container>
      <OrganizationForm 
        type="CREATE"
        :organization="organization"
      />
    </v-container>
  </v-card>
</template>

<script>
import OrganizationForm from "./form"

export default {
  components: {
    OrganizationForm
  },
  data() {
    return {
      organization: {}
    }
  }
};
</script>
